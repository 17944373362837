<template>
  <div>
    <v-container fluid >
      <v-row class="align-center justify-center">
        <v-col cols="12">
          <div style="height: 890px; overflow-y: auto;">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>User</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-if="mySelf.roles.includes('superUser') || mySelf.roles.includes('admin')"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
             
              <v-btn color="green" fab small dark class="mb-2" @click="createItem" v-if="mySelf.roles.includes('superUser') || mySelf.roles.includes('admin')">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-dialog v-model="dialog" max-width="750px" persistent>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-alert dismissible  :value="alert" type="error">{{alertMsg}}</v-alert> 
                    <v-form ref="form" v-model="valid" >
                      <v-container>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field :rules="requiredRule"
                            required v-model="editedItem.displayname" label="Name" 
                            :disabled="!mySelf.roles.includes('superUser') && !mySelf.roles.includes('admin')"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field :rules="emailRule" :disabled="!mySelf.roles.includes('superUser') && !mySelf.roles.includes('admin')"
                            required v-model="editedItem.email" label="Email"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field :rules="requiredRule" 
                            required v-model="editedItem.password_plain" label="Password"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field :rules="keyRule" :disabled="!mySelf.roles.includes('superUser') && !mySelf.roles.includes('admin')"
                            required v-model="editedItem.device_location.attributes.guek" label="GUEK"></v-text-field>
                          </v-col>         
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.device_location.attributes.counter_number_consumption" label="ZP Bezug" 
                            :disabled="!mySelf.roles.includes('superUser') && !mySelf.roles.includes('admin')"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.device_location.attributes.counter_number_production" label="ZP Einspeisung" 
                            :disabled="!mySelf.roles.includes('superUser') && !mySelf.roles.includes('admin')"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.device_location.attributes.meter_number" label="Zählernummer" 
                            :disabled="!mySelf.roles.includes('superUser') && !mySelf.roles.includes('admin')"></v-text-field>
                          </v-col>   
                          <v-checkbox
                            :disabled="!mySelf.roles.includes('superUser') && !mySelf.roles.includes('admin')"
                            v-model="editedItem.dsgvo"
                            :rules="requiredRule"
                            required
                          >

                            <template v-slot:label>
                              
                              <div>
                                Dieser User stimmt bis auf Widerruf der 
                                    <!-- <a
                                      target="_blank"
                                      href="/dsgvo"
                                      @click.stop
                                    > -->
                                      Datenschutzerklärung der Fa. Thomas Schwarzl IT Consulting
                                    <!-- </a> -->
                                zu.
                              </div>
                            </template>
                          </v-checkbox>
                          <!-- <v-col cols="12" md="4" v-if="locationTypes">
                            <v-autocomplete
                              :items="locationTypes"
                              item-text="value"
                              item-value="_id"                             
                              label="Location Type" 
                              v-model="editedItem.type"
                              v-validate="'required'"
                              data-vv-name="form_location_type"
                              :error-messages="errors.collect('form_location_type')"
                            ></v-autocomplete>
                          </v-col> -->
                         
                          <!-- <v-col cols="12" md="6">
                            <v-autocomplete
                              :items="locationNames" 
                              label="Location" 
                              v-model="location_index"
                              @change="set_location"
                              item-text="name"
                              item-value="index"
                            ></v-autocomplete>
                          </v-col> -->
                           <!-- 
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.icon" label="icon"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.active" label="active"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.sortorder" label="sortorder"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field v-model="editedItem.attributes" label="attributes"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6" v-if="check_room(editedItem.type)">
                            <v-checkbox v-model="editedItem.createHA" label="Create HeatArea"></v-checkbox>
                          </v-col> -->
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="save">Save</v-btn>
                    <!-- <v-alert dismissible class="alertStyle" :value="alert" type="error">{{alertMsg}}</v-alert> -->
                  </v-card-actions>
                </v-card>
               
              </v-dialog>
            </v-toolbar>
         
            <v-data-table        
              :headers="headers"
              :items="filteredUsers"
              class="elevation-1"
              :search="search"
              :footer-props="{ 'items-per-page-options': [10, 25, 100, -1] }"
              :items-per-page="50"
              sort-by="displayname"
              must-sort
            >

              <!-- <template v-slot:item.location="{ item }">
                {{item.location.name}}
              </template> -->

              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)"
                  >mdi-pencil</v-icon
                >
                <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
              </template>

              <!-- <template v-slot:item="{ item }" class="text-xs-right">
                <tr >
                  <td>{{ item.displayname }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.password_plain }}</td>
                  <td>{{ item.location.name }}</td>
                  <td>{{ item.device_location.name }}</td>

                  <td>{{ item.guek }}</td>
                
                
                  <td class="justify-center layout px-0">
                    <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
                    <v-icon small @click="deleteItem(item)">mdi-cancel</v-icon>
                  </td>
                </tr>
              </template> -->
            </v-data-table>
          </div>
        </v-col>
      </v-row>

      
      
    </v-container>
  </div>
</template>


<script>
/* eslint-disable */
import _ from "lodash";
import { mapActions, mapState } from "vuex";
// import api from "../api";
// import draggable from 'vuedraggable'
// import nestedDraggable from "./nested";
// import { randomUUID } from 'crypto';
import { requiredRule, emailRule ,passwordRule, keyRule} from "../utils/rules";

export default {
  name: "user",
  components: {
    // draggable,
    // nestedDraggable
  },
  data() {
    return {
      alert: false,
      alertMsg: "",
      requiredRule,
      emailRule,
      passwordRule,
      keyRule,
      valid: false,
      devices: [],
      /*
      treeDrop: {
        item: "",
        parent: ""
      },*/
      location: {},
      locations: [],
      dialog: false,
      search: '',
      search2: '',
      parent_location_index: -1,
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'displayname'
        },
        { text: 'email', value: 'email' },
        { text: 'Passwort', value: 'password_plain' },
        // { text: 'Location', value: 'location.name' },
        { text: 'Zählernummer', value: 'device_location.name' },
        { text: 'GUEK', value: 'device_location.attributes.guek' },
        { text: 'ZP Bezug', value: 'device_location.attributes.counter_number_consumption' },
        { text: 'ZP Einspeisung', value: 'device_location.attributes.counter_number_production' },
        { text: 'Zählernummer', value: 'device_location.attributes.meter_number' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
       
      },
      defaultItem: {
        displayname: '',
        email: "",
        password_plain: "",
        location: null,
        device_location: {attributes: {guek: "", counter_number_production: "", counter_number_consumption: "", meter_number: ""}},
        dsgvo: false
      },
      sortedUsersId: [],
      sortedUsers: [],
      /*items2: [] ,*/
      treeLocations: [],
      projectId: "",
      locationNames: [],
      // userLoaded: false,
      filteredUsers: [],
     /* treeLocations2:  [
        {
          name: "task 1",
          _id: 1,
          child: [
            {
              name: "task 2",
              _id: 2,
              child: []
            }
          ]
        },
        {
          name: "task 3",
          _id: 3,
          child: [
            {
              name: "task 4",
              _id: 4,
              child: [
                {
                  name: "task 6",
                  _id: 6,
                  child: [
                    {
                      name: "task 7",
                      _id: 7,
                      child: []
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          name: "task 5",
          _id: 5,
          child: []
        }
      ]*/
    }
    
  },
  created() {
    this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
  },
  async mounted() {

    // this.initData()
    if (this.Global.length == 0) {
        await this.getGlobal({});
      }
    // this.projectId = (_.find(this.Global.locationTypes, {value: "project"}))._id;
    await this.getLocation({})
    // this.genLocationNamesWithProject();
    // console.log("GET USERS")
    await this.getUser({})
    //console.log("GET USERS ", this.Users)
    this.comp_filtered_user();

    

  },
  methods: {
    ...mapActions(
      "location", [
        "getLocation",
        "deleteLocation",
        "updateLocation",
        "createLocation",
        "deactivateLocation"
        ]
      ),
    ...mapActions(
      "user", ["getUser", "createUser", "updateUser"]
      ),
    ...mapActions(
      "device", [
        "getDevicesAll",
        "updateDeviceLocation",
        "getPowerDevicesOnly",
        ]
      ),
    ...mapActions(
      "global", ["getGlobal"]
      ),
    async initData() {
     
      // 
      // await this.getDevicesAll({})
      // await this.getPowerDevicesOnly({})  
    },
    editItem (item) {    
      this.editedIndex = this.filteredUsers.indexOf(item)
      // console.log("editItem ", item, this.editedIndex)
      this.editedItem = Object.assign({}, item)
      this.parent_location_index = this.Locations.findIndex((loc) => {return loc._id == this.editedItem.parent})
      this.editedItem.attributes = JSON.stringify(this.editedItem.attributes)
      this.dialog = true
    },

    createItem() {
      //console.log(this.defaultItem)
      this.editedIndex = -1;
      //this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      //console.log(this.editedItem)
      this.parent_location_index = -1;
      const charset = 'abcdefghijklmnopqrstuvwxyz123456789'; //!@#$%^&*()_+~`|}{[]:;?><,./-=
      for (let i = 0; i < 8; i++) {
          const randomIndex = Math.floor(Math.random() * charset.length);
          this.editedItem.password_plain += charset[randomIndex];
        }
      this.dialog = true
    },


    async deleteItem (item) {
      const index = this.filteredUsers.indexOf(item)
      //console.log("ITEM: " + item._id)
      confirm('Are you sure you want to delete this item?') && await this.deleteLocation(item._id)
      await this.getLocation({})
    },

    async deactivateItem (item) {
      const index = this.filteredUsers.indexOf(item)
      //console.log("ITEM: " + item._id)
      //confirm('Are you sure you want to deactivate this item?') && 
      await this.deactivateLocation(item._id)
      await this.getLocation({})
    },

    async activateItem (item) {
      const index = this.Locations.indexOf(item)
      await this.deactivateLocation(item._id)
      await this.getLocation({})
    },

    close () {
      this.dialog = false
      this.alert = false;
      setTimeout(() => {
        //this.editedItem = Object.assign({}, this.defaultItem)
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
        this.editedIndex = -1
      }, 300)
    },

    async save () {
      //console.log(this.editedItem, this.editedIndex)
      this.$refs.form.validate();
      // console.log(this.valid, this.token);

      if (this.valid) {
        
        try {
          if (this.editedIndex > -1) {
            // if (typeof this.editedItem.attributes == "string") {
            //   this.editedItem.attributes = JSON.parse(this.editedItem.attributes);
            // }
            // await this.updateLocation(this.editedItem)
            this.editedItem.password = this.editedItem.password_plain;
            await this.updateUser(this.editedItem);
            if (!this.mySelf.roles.includes('user')) {
              await this.updateLocation(this.editedItem.device_location);
            }
            //console.log(this.editedItem)
            this.close();
          } else {  //new item
            // if (typeof this.editedItem.attributes == "string") {
            //   this.editedItem.attributes = JSON.parse(this.editedItem.attributes);
            // }
            // await this.createLocation(this.editedItem);

            // if (this.editedItem.createHA && this.check_room(this.editedItem.type)) {
            //   let ha = JSON.parse(JSON.stringify(this.editedItem));
            //   ha.name = "HA " + ha.name;
            //   ha.type = (_.find(this.Global.locationTypes, {value: "heatarea"}))._id;
            //   // console.log("CREATE HA: ", this.newLocation._id)
            //   ha.parent = this.newLocation._id;
            //   await this.createLocation(ha);
            // }
            const new_user = {
              displayname: this.editedItem.displayname,
              email: this.editedItem.email,
              dsgvo: this.editedItem.dsgvo,
              password: this.editedItem.password_plain,
              activated: true,
              sendmail: true
            }

            await this.createUser(new_user);

            if (this.StatusUser == "success") {
              //console.log("CREATE User Success ", this.User);
              const building_type = (_.find(this.Global.locationTypes, {value: "building"}));
              let new_location = {
                name: this.editedItem.displayname,
                parent: this.mySelf.myLocations[0],
                active: true,
                type: building_type._id,
                user: [this.User._id]
              }
              await this.createLocation(new_location);
              
              if (this.StatusLocationCreate == "success") {
                const electricity_meter_type = (_.find(this.Global.locationTypes, {value: "electricity meter"}));
              
                new_location = {
                  name: "New Device",
                  parent: this.newLocation._id,
                  active: true,
                  type: electricity_meter_type._id,
                  user: [],
                  attributes: {"guek": this.editedItem.device_location.attributes.guek, "counter_number_consumption": this.editedItem.device_location.attributes.counter_number_consumption, "counter_number_production": this.editedItem.device_location.attributes.counter_number_production, "meter_number": this.editedItem.device_location.attributes.meter_number}
                }        
              
                await this.createLocation(new_location);
                if (this.StatusLocationCreate == "success") {
                  //console.log(this.newLocation)
           
                  this.close();
                }
              }

              
            } else {
              this.alert = true;
              // console.log(this.StatusUser)
              this.alertMsg = this.StatusUser.message;
            }
            

          }
          await this.getLocation({});
          await this.getUser({});
         //console.log(this.Users.length)
          // console.table(this.Users);
          this.comp_filtered_user()
          //console.log(this.filteredUsers.length)
          // console.table(this.filteredUsers);
          // await this.getLocation({});
          // this.genLocationNamesWithProject();
          
        } catch (err) {
          console.log(err);
        }
      }
    },

    comp_user (users) {
      try {
        let user_array = []
        //console.log(this.Users)
        users.forEach(obj => {
          user_array.push(this.Users.filter(fobj => {return fobj._id == obj})[0].email)
        }) 
        return user_array
      }
      catch {
        return []
      }
    },
    comp_user_id (id) {
      //console.log(id)
      try {
        return {displayname: this.Users.filter(fobj => {return fobj._id == id})[0].displayname , email: this.Users.filter(fobj => {return fobj._id == id})[0].email}
      }
      catch {
        return ""
      }
    },

    comp_location (id) {
      try {
        return this.locationNames.filter(obj => {return obj._id == id})[0].name      
      }
      catch {
        return ""
      }
    },

    comp_locationTypes (id) {
      //console.log(this.Global.locationTypes)
      try {
         return this.Global.locationTypes.filter(obj => {return obj._id == id})[0].value;
      }
      catch {
        return ""
      }
    },

    check_room (type) {
      if ((_.find(this.Global.locationTypes, {value: "room"}))._id == type) {
        return true;
      } else {
        return false;
      }
    },
    /*findItemNested(arr, itemId, nestingKey) {
      //console.log(arr)
      arr.reduce((a, item) => {
          if (a) return a
          if (item._id === itemId) return item
          if (item[nestingKey]) return this.findItemNested(item[nestingKey], itemId, nestingKey)
      })
    },

    findById(arr, id, nestingKey) {
      
      // if empty array then return
      if(arr.length == 0) return
      
      // return element if found else collect all children(or other nestedKey) array and run this function
      return arr.find(d => d._id == id) 
          || this.findById(arr.flatMap(d => d[nestingKey] || []), id)
          || null
    },*/

    customFilter(object, _id){
      if (object) {
        if(object.hasOwnProperty('_id') && object["_id"] == _id)
            return object

        for(let i=0; i<Object.keys(object).length; i++){
            if(typeof object[Object.keys(object)[i]] == "object"){
                let o = this.customFilter(object[Object.keys(object)[i]], _id)
                if(o != null)
                    return o
            }
        }
      }
      return null
    },

    comp_tree_location () {
        this.treeLocations = _.cloneDeep(this.Locations.filter(obj => {return obj.parent == null}))

        this.treeLocations.forEach(loc => {
          loc.type_name = this.comp_locationTypes(loc.type)
          loc.child = []
        })
        let tree = _.sortBy(_.cloneDeep(this.Locations.filter(obj => {return obj.parent != null})), "name");     
        let iteration = 0
        
        while (tree.length > 0) {
          let treeindex = []
          tree.forEach( (loc,index) => {

            let findobj = this.customFilter(this.treeLocations, loc.parent)

            if (findobj) {
              loc.child = []
              loc.type_name = this.comp_locationTypes(loc.type)
              findobj.child.push(loc)
              treeindex.push(index)
            }       
          })
          _.pullAt(tree, treeindex)
          iteration++
          if (iteration > 1000) break
        }
    },

    set_parent_location (index) {
      this.editedItem.parent = this.Locations[index]._id
    },

    cloneUser(item) {
      //console.log("Clone")
      //console.log(item)
      //console.log(index)
      /*if(findIndex(this.items2, function(o) { return o._id == item._id; }) == -1) {
        return item._id
      }*/
    },

    async logDrop(item) {
      //console.log("Drop")
      //console.log(item)
      this.editedIndex = _.findIndex(this.Locations, function(o) { return o._id == item; })
      this.editedItem = this.Locations[this.editedIndex]
      //console.log(this.editedIndex)
      //console.log(this.editedItem)
      await this.save()
      
      //console.log(this.editedIndex)
      this.editedIndex = -1
      this.editedItem = []
      this.sortedUsersId = _.cloneDeep(this.compSortedUsersId)
      
      this.getPowerDevicesOnly({})

    },
    async logDropDevices(item, locationId) {
      //console.log("DropDevices ", item)
      if(item.added) {
        await this.saveDeviceLocation(item.added.element._id, locationId)
      }
      else if (item.removed) {
        await this.saveDeviceLocation(item.removed.element._id, null)
      }
      
      await this.getDevicesAll({})
      this.getPowerDevicesOnly({})

    },
    logEnd(item) {
      //console.log("End")
      //console.log(item)
    },

    logMove(item) {
      // console.log("Move")
      // console.log(item)
    },

    changeTree(item) {
      // console.log("TREE")
      // console.log(item)
    },
    async saveDeviceLocation(deviceId, locationId) {
      //console.log({deviceId, locationId})
      await this.updateDeviceLocation({deviceId, locationId})
    },
    getProjectOf(loc) {
      let _loc = JSON.parse(JSON.stringify(loc));
      try {
        if (_loc) {
          while (_loc.type != this.projectId) {
            _loc = _.find(this.Locations, {_id: _loc.parent});
            if (!_loc) break;
          }

          if (_loc) {
            return _loc.name;
          } else {
            return "";
          }
        } else {
          return "";
        }      
      } catch(err) {
        console.log("getProjectOf Error ", err, _loc);
        return "";
      } 
    },
    genLocationNamesWithProject () {
      // if (this.Locations.length > 0) {
        let locnames = [{name: "", index: -1}]
        this.Locations.forEach((loc, index) => {
          const projectname = this.getProjectOf(loc);
          let parent = "";
          if (loc.parent) {
            parent = this.Locations.filter(obj => {return obj._id == loc.parent})[0];
          }
          let grandParent = "";
          if (parent.parent) {
            grandParent = this.Locations.filter(obj => {return obj._id == parent.parent})[0];
          }
          //console.log(parentName, loc.parent)
          if (projectname == loc.name || projectname == "") {
            locnames.push({name: loc.name, index: index, _id: loc._id});
          } else {
            locnames.push({name: loc.name + " (" + projectname + "/" + grandParent.name + "/" + parent.name + ")", index: index, _id: loc._id});
          }
        })
        this.locationNames = _.sortBy(locnames,"name");
      // } else {
      //   return []
      // }
    },

    comp_filtered_user() {
      
    // if (this.Users && this.Global) {
    //   const start = this.startUserFiltering;
      
      try {
        
        let users = JSON.parse(JSON.stringify(this.Users));
        
       
          let electricitymeter_type = (_.find(this.Global.locationTypes, {value: "electricity meter"}));
          // console.log(electricitymeter_type._id)
          let building_type = (_.find(this.Global.locationTypes, {value: "building"}));
          // console.log(building_type._id)
          for (let user of users) {
            //console.log(user)
            user.location = this.Locations.find(obj => {return obj.user.includes(user._id)})
            if (user.location) {
              user.device_location = this.Locations.find(obj => {return obj.type == electricitymeter_type._id && obj.parent == user.location._id})
              if (!user.device_location) {
                user.device_location = {};
              }
              if (!user.device_location.attributes) {
                user.device_location.attributes = {}
              }
            }
          }

        if (this.mySelf.roles.includes("superUser")) {
          users = users.filter(obj => {return obj?.location?.type == building_type._id});
        }

        this.filteredUsers = users;
        // return users
        
        // this.userLoaded = true;
      } catch(err) {
        console.log(err);
      }
      //console.log("filteredusers")
      
     
    
    }
  },
  computed: {
    ...mapState({
      Locations: ({ location }) => location.Locations,
      Users: ({ user }) => user.Users,
      User: ({ user }) => user.User,
      StatusUser: ({ user }) => user.statusUser,
      Loading: (state) => state.loading,
      moveLocation: ({ location }) => location.moveLocation,
      newLocation: ({ location }) => location.newLocation,
      StatusLocationCreate: ({ location }) => location.statusCreate,
      devicesAll: ({device}) => device.devicesAll,
      powerDevicesOnly: ({ device }) => device.powerDevicesOnly,
      Global: ({ global }) => global.Global,
      mySelf: ({ auth }) => auth.mySelf,
    }),
    formTitle () {
      return this.editedIndex === -1 ? 'Neuer User' : 'Editiere User'
    },

    filteredLocations() {
     
      //console.log("filteredLocations")
      this.locations = [];
      // const search1 = this.search.toString().toLowerCase();
      // const search2 = this.search2.toString().toLowerCase();

      for (let loc of this.Locations) {

        const parentObject = this.Locations.filter(obj => {return obj._id == loc.parent})[0];
        // console.log("parentObject ", parentObject)
        if (parentObject) {
          loc.parentName = parentObject.name;
        }

        loc.typeName = this.comp_locationTypes(loc.type);
        loc.projectName = this.getProjectOf(loc);
        this.locations.push(loc);

      }
      //this.locations = this.Locations
      //this.locations = _.cloneDeep(this.Locations)
      // let d = new Date();
      // let ms = d.getMilliseconds();
      //console.log(d, ms);
      this.locations.forEach(loc => {
        loc.devices = [];

        let devices = _.filter(this.devicesAll, {location:loc._id});

       
       /* this.devicesAll.forEach(device => {
          if(loc._id === device.location) {
            loc.devices.push(device);
          }
        })*/
        devices.forEach(device => {
          device.locationName = loc.name;
          loc.devices.push(device);
        })
       
        loc.user.forEach(user_id => {
          let user = _.find(this.Users, {_id: user_id});       
          if (user) {
            //console.log("Found User in Location: " + loc.name + " User: " + user.displayname);
            user.location = loc;
          }
        })
      })
      // d = new Date();
      // ms = d.getMilliseconds();
      // console.log(d, ms);
      this.sortedUsersId = _.cloneDeep(this.compSortedUsersId);
      this.sortedUsers = _.cloneDeep(this.compSortedUsers);
      this.devices = _.sortBy(_.cloneDeep(this.devicesAll), "name");
      
      // const f = this.locations.filter(obj => {return obj.name.toLowerCase().includes(search1) || obj.name.toLowerCase().includes(search2)})
      // return f;
      return this.locations;
    },

    

    // locationNames () {
    //   if (this.Locations.length > 0) {
    //     let locnames = [{name: "", index: -1}]
    //     this.Locations.forEach((loc, index) => {
    //       let projectname = this.getProjectOf(loc);
    //       locnames.push({name: projectname + loc.name, index: index})
    //     })
    //     return _.sortBy(locnames,"name");
    //   } else {
    //     return []
    //   }
    // },
    locationTypes () {
      return _.sortBy(this.Global.locationTypes,"value");
    },
    compSortedUsers () {
      const _users = _.filter(this.Users, {activated: true});
      let sortedUser = _.sortBy(_users, 'displayname');
      return sortedUser;
    },
    compSortedUsersId () {
      const _users = _.filter(this.Users, {activated: true});
      let sorted = _.sortBy(_users, 'displayname')
      let result = []
      sorted.forEach(obj => {
        result.push(obj._id)
      })
      this.sortedUsersId = _.cloneDeep(result)
      return result
    },
    

  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    locations () {
      this.comp_tree_location()
    },
    Users () {
      //console.log("Users loaded")
      this.sortedUsersId = _.cloneDeep(this.compSortedUsersId)
      this.sortedUsers = _.cloneDeep(this.compSortedUsers)
    },
    devicesAll () {
      this.devices = _.sortBy(_.cloneDeep(this.devicesAll), "name");
    },
    /*treeDrop: {   //funkt bei root nicht deshalb über store -> moveLocation
      deep: true,

      handler() {
        console.log ("WATCH LastChanged")
        console.log(this.treeDrop)   
        this.treeLocations = _.cloneDeep(this.treeLocations)
      }
    },*/
    moveLocation () {
      this.editedIndex = this.Locations.findIndex((loc) => {return loc._id == this.moveLocation.item})
      this.editedItem = this.Locations[this.editedIndex]
      this.editedItem.parent = this.moveLocation.parent
      this.treeLocations = _.cloneDeep(this.treeLocations)
      this.save()

      this.getPowerDevicesOnly({})
    },

  }
}

</script>